<template>
  <ModalBgWrapper
    class-name="z-[101]"
    :open="
      stepId === 'selectWallet' ||
      stepId === 'selectNetwork' ||
      stepId === WalletOption.KEYSTORE ||
      stepId === 'fromPhrase' ||
      stepId === 'phrase' ||
      stepId === 'password' ||
      stepId === 'result'
    "
    body-class="border-none rounded-t-3xl md:rounded-b-3xl bg-[#101010] shadow-[0px_966px_270px_0px_rgba(102,_75,_0,_0),_0px_618px_247px_0px_rgba(102,_75,_0,_0.02),_0px_348px_209px_0px_rgba(102,_75,_0,_0.08),_0px_155px_155px_0px_rgba(102,_75,_0,_0.14),_0px_39px_85px_0px_rgba(102,_75,_0,_0.16)]"
    @close="onClose"
  >
    <ChildWalletSelect v-if="stepId === 'selectWallet'" @select-wallet="onSelectWallet" />
    <ChildNetworkSelect
      v-else-if="stepId === 'selectNetwork'"
      :wallet="wallet"
      @connect-wallet="onConnectWallet"
      @return="onGoToSelectWallet"
      @toggle-chain="onToggleChain"
      @select-all="onSelectAllChains"
      @deselect-all="onDeselectAllChains"
    />
    <GrandKeystoreWallet
      v-else-if="stepId === WalletOption.KEYSTORE"
      :on-connect="onConnectFromPhrase"
      @close="onGoToNetworkSelect"
    />
    <GrandFromPhrase
      v-else-if="stepId === 'fromPhrase'"
      :on-connect="onConnectFromPhraseAndDownload"
      @close="onClose"
    />
    <GrandConfirmSeedPhrase
      v-else-if="step.key === 'phrase' && stepId === 'phrase'"
      :seed-phrase="step.phrase"
      @stored="onPhraseStored"
      @close="onClose"
    />
    <GrandNewKeystoreWallet
      v-else-if="stepId === 'password'"
      @download="onDownloadKeystore"
      @close="onGoToPhrase"
    />
    <GrandResult
      v-if="step.key === 'result' && stepId === 'result'"
      :wallet="step.wallet"
      @close="onClose"
    />
  </ModalBgWrapper>
</template>

<script setup lang="ts">
import {
  type Keystore,
  encryptToKeyStore,
  generatePhrase,
  validatePhrase,
} from '@xchainjs/xchain-crypto'
import { Chain, WalletOption } from '@swapkit/helpers'
import { type DeepReadonly, computed, onMounted, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useRuntimeConfig } from 'nuxt/app'
import ChildNetworkSelect from './CompWalletConnect/ChildNetworkSelect.vue'
import ChildWalletSelect from './CompWalletConnect/ChildWalletSelect.vue'
import GrandFromPhrase from './CompWalletConnect/GrandFromPhrase.vue'
import GrandResult from './CompWalletConnect/GrandResult.vue'
import GrandConfirmSeedPhrase from './CompWalletConnect/GrandConfirmSeedPhrase.vue'
import GrandNewKeystoreWallet from './CompWalletConnect/GrandNewKeystoreWallet.vue'
import GrandKeystoreWallet from './CompWalletConnect/GrandKeystoreWallet.vue'
import {
  type EvmChain,
  type KeplrChain,
  type KeystoreChain,
  type Wallet,
  type Wallets,
  type XdefiChain,
  connectChainsRecord,
  evmChains,
  keplrChains,
  keystoreChains,
  xdefiChains,
} from '~/utils/walletconnect'
import {
  type WalletChain,
  type XdefiEvmConfig,
  type XdefiSolanaConfig,
  assertMetamaskIsInstalled,
  assertPhantomIsInstalled,
  assertXdefiEvmInstalled,
  assertXdefiSolanaInstalled,
} from '~/wallets/swapkit'
import { useConnectWalletModalStore } from '~/store/connectWalletModalStore'
import { useSwapkitWalletStore } from '~/store/swapkitWalletStore'
import { useAnalytics } from '~/composables/analytics'
import { useSnackbarMessage } from '~/store/snackbar'
import { hasMessage } from '~/utils/main'
import { RangoChain } from '~/types/rango'
import { getHumanError } from '~/utils/humanErrors'
import { isEvmChain } from '~/utils/swapkit'

const runtimeConfig = useRuntimeConfig()
const analytics = useAnalytics()
const snackbar = useSnackbarMessage()

const swapkitWalletStore = useSwapkitWalletStore()
const { connectWallet } = swapkitWalletStore
const { isLoadingBalances } = storeToRefs(swapkitWalletStore)

const connectWalletModalStore = useConnectWalletModalStore()
const { connectWalletIsVisible } = storeToRefs(connectWalletModalStore)
const { hideConnectWalletModal } = connectWalletModalStore

type Step =
  | { key: 'selectWallet' }
  | ({ key: 'selectNetwork' } & { readonly wallet: Wallet })
  | ({ key: WalletOption.KEYSTORE } & {
      readonly wallet: DeepReadonly<Wallets[WalletOption.KEYSTORE]>
    })
  | ({ key: 'fromPhrase' } & { readonly wallet: DeepReadonly<Wallets['from-phrase']> })
  | ({ key: 'phrase' } & {
      readonly phrase: string
      readonly wallet: DeepReadonly<Wallets['create-new']>
    })
  | ({ key: 'password' } & {
      readonly phrase: string
      readonly wallet: DeepReadonly<Wallets['create-new']>
    })
  | ({ key: 'result' } & { readonly wallet: DeepReadonly<Wallet> })
const initialStep: Step = { key: 'selectWallet' }
const step = ref<Step>({ ...initialStep })
const stepId = computed<Step['key'] | 'closed'>(() => {
  if (!connectWalletIsVisible.value) {
    return 'closed'
  }
  return step.value.key
})
const wallet = computed<Wallet | null>(() => {
  if (step.value.key !== 'selectNetwork') {
    return null
  }
  return step.value.wallet
})

const chainsToSelectedChains = <
  Chains extends Wallet['chains'] | ReadonlyArray<Wallet['chains'][number]>,
>(
  chains: Chains,
) => {
  return chains.reduce(
    (selectedChains, chain) => {
      return { ...selectedChains, [chain.id]: chain.status === 'selected' }
    },
    {} as Record<Chains[number]['id'], boolean>,
  )
}
const connectPhantom = () => {
  assertPhantomIsInstalled(window.phantom)
  const provider = window.phantom.solana
  return connectWallet({
    wallet: WalletOption.PHANTOM,
    provider,
  })
}
const connectXdefi = (chains: XdefiChain[]) => {
  const selectedChains = chainsToSelectedChains(chains)
  const withSolProvider: Pick<XdefiSolanaConfig, 'solProvider'> | null = (() => {
    if (!selectedChains[RangoChain.Solana]) {
      return null
    }
    const xdefi = window.xfi
    assertXdefiSolanaInstalled(xdefi)
    return { solProvider: xdefi.solana }
  })()
  const withEvmProvider: Pick<XdefiEvmConfig, 'evmProvider'> | null = (() => {
    if (!chains.some((chain) => isEvmChain(chain.id))) {
      return null
    }
    const xdefi = window.xfi
    assertXdefiEvmInstalled(xdefi)
    return { evmProvider: xdefi.ethereum }
  })()
  return swapkitWalletStore.connectWallet({
    wallet: WalletOption.XDEFI,
    selectedChains,
    ...withEvmProvider,
    ...withSolProvider,
  })
}
const connectMetamask = (chain: EvmChain) => {
  assertMetamaskIsInstalled(window.ethereum)
  return connectWallet({
    wallet: WalletOption.METAMASK,
    chain: chain.id,
    provider: window.ethereum,
  })
}
const connectKeplr = (chains: KeplrChain[]) => {
  return connectWallet({
    wallet: WalletOption.KEPLR,
    selectedChains: chainsToSelectedChains(chains),
  })
}
const connectKeystore = ({
  chains,
  phrase,
}: {
  chains: { [K in KeystoreChain['id']]: boolean }
  phrase: string
}) => {
  const runtimeConfig = useRuntimeConfig()
  const ethProviderApiKey = runtimeConfig.public.ETH_PROVIDER_API_KEY
  const arbProviderApiKey = runtimeConfig.public.ARB_PROVIDER_API_KEY
  const solanaNetworkUrl = runtimeConfig.public.SOLANA_NETWORK_URL
  return swapkitWalletStore.connectWallet({
    wallet: WalletOption.KEYSTORE,
    selectedChains: chains,
    phrase,
    ethProviderApiKey,
    arbProviderApiKey,
    solanaNetworkUrl,
  })
}
const downloadKeystore = (keystore: Keystore) => {
  const a = document.createElement('a')
  const download = `keystore-${Date.now()}.json`
  a.setAttribute('download', download)
  const keystoreData = window.btoa(JSON.stringify(keystore))
  a.href = 'data:application/json;base64,' + keystoreData
  document.body.appendChild(a)
  a.click()
  a.remove()
}
const encryptAndDownloadKeystore = async ({
  phrase,
  password,
}: {
  phrase: string
  password: string
}) => {
  try {
    const keystore = await encryptToKeyStore(phrase, password)
    downloadKeystore(keystore)
  } catch (error) {
    const title = 'Failed to download Keystore file'
    const errorMessage =
      (typeof error === 'string' && error) || (hasMessage(error) && error.message) || ''
    snackbar.addError({ title, text: getHumanError(errorMessage) })
    window.newrelic?.noticeError(errorMessage || title)
  }
}
const onConnectFromPhrase = async (phrase: string): Promise<boolean> => {
  const errorTitle = 'Failed to recover Keystore wallet'
  if (step.value.key !== 'fromPhrase' && step.value.key !== WalletOption.KEYSTORE) {
    snackbar.addWarning({
      title: errorTitle,
      text: 'Only a keystore wallet can be recovered from seed phrase',
    })
    return false
  }
  step.value = { key: 'result', wallet: step.value.wallet }
  /**
   * clones chains for post processing because ref is reset on component unmount
   */
  const chains = step.value.wallet.chains
  try {
    await connectKeystore({ phrase, chains: chainsToSelectedChains(step.value.wallet.chains) })
  } catch (error) {
    const errorMessage =
      (typeof error === 'string' && error) || (hasMessage(error) && error.message) || ''
    window.newrelic?.noticeError(errorMessage || errorTitle)
    snackbar.addError({ title: errorTitle, text: getHumanError(errorMessage) })
    return false
  }
  localStorage.setItem('lastWallet', WalletOption.KEYSTORE)
  localStorage.setItem(
    'connectChains',
    chains.flatMap(({ status, id }) => (status === 'selected' ? id : [])).join(','),
  )
  analytics.track('wallet-connected', {
    wallet: 'keystore',
    type: 'recover',
  })
  trackWalletConnection()
  return true
}
const onConnectFromPhraseAndDownload = async ({
  phrase,
  password,
}: {
  phrase: string
  password: string
}) => {
  if (!(await onConnectFromPhrase(phrase))) {
    return
  }
  await encryptAndDownloadKeystore({ phrase, password })
}
const connectNewKeystore = async ({
  phrase,
  chains,
}: {
  phrase: string
  chains: KeystoreChain[]
}) => {
  await connectKeystore({ chains: chainsToSelectedChains(chains), phrase })
  return phrase
}
const onPhraseStored = () => {
  if (step.value.key !== 'phrase') {
    snackbar.addWarning({ title: 'Cannot proceed to result step without wallet' })
    return
  }
  step.value = { key: 'password', phrase: step.value.phrase, wallet: step.value.wallet }
}
const onDownloadKeystore = async (password: string) => {
  if (step.value.key !== 'password') {
    snackbar.addWarning({
      title: 'Failed to download Keystore',
      text: 'Invalid wallet or missing phrase',
    })
    return
  }
  await encryptAndDownloadKeystore({ phrase: step.value.phrase, password })
  step.value = { key: 'result', wallet: step.value.wallet }
}
/**
 * Connect wallet
 * @reference https://v2.growthchannel.io/activate/pixels
 */
const trackWalletConnection = () => {
  if (runtimeConfig.public.NODE_ENV !== 'production') {
    return
  }
  const connectWalletPixel = document.createElement('script')
  connectWalletPixel.async = true
  connectWalletPixel.defer = true
  connectWalletPixel.src = 'https://pxl.growth-channel.net/s/97c51839-d64d-49bb-bdd2-8e66ca34982f'
  document.body.appendChild(connectWalletPixel)
}
const onConnectWallet = async (wallet: Wallet) => {
  switch (wallet.id) {
    case WalletOption.PHANTOM:
      try {
        await connectPhantom()
        step.value = { key: 'result', wallet }
        localStorage.setItem('lastWallet', WalletOption.PHANTOM)
        localStorage.setItem(
          'connectChains',
          wallet.chains.flatMap(({ status, id }) => (status === 'selected' ? id : [])).join(','),
        )
        analytics.track('wallet-connected', {
          wallet: 'phantom',
        })
        trackWalletConnection()
      } catch (error) {
        window.open('https://phantom.app/', '_blank')

        const title = 'Failed to connect to Phantom wallet'
        const errorMessage =
          (typeof error === 'string' && error) || (hasMessage(error) && error.message) || ''
        window.newrelic?.noticeError(errorMessage || title)
        snackbar.addError({ title, text: getHumanError(errorMessage) })
      }
      break
    case WalletOption.XDEFI:
      try {
        await connectXdefi(wallet.chains)
        step.value = { key: 'result', wallet }
        localStorage.setItem('lastWallet', WalletOption.XDEFI)
        localStorage.setItem(
          'connectChains',
          wallet.chains.flatMap(({ status, id }) => (status === 'selected' ? id : [])).join(','),
        )
        analytics.track('wallet-connected', {
          wallet: 'xdefi',
        })
        trackWalletConnection()
      } catch (error) {
        window.open('https://go.xdefi.io/eldorado', '_blank')

        const title = 'Failed to connect to xdefi wallet'
        const errorMessage =
          (typeof error === 'string' && error) || (hasMessage(error) && error.message) || ''
        window.newrelic?.noticeError(errorMessage || title)
        snackbar.addError({ title, text: getHumanError(errorMessage) })
      }
      break
    case WalletOption.METAMASK: {
      const title = 'Failed to connect to Metamask wallet'
      const chain = wallet.chains.find((c) => c.status === 'selected')
      if (!chain) {
        snackbar.addError({ title, text: 'Please select a chain' })
        return
      }
      try {
        await connectMetamask(chain)
        step.value = { key: 'result', wallet }
        localStorage.setItem('lastWallet', WalletOption.METAMASK)
        localStorage.setItem(
          'connectChains',
          wallet.chains.flatMap(({ status, id }) => (status === 'selected' ? id : [])).join(','),
        )
        analytics.track('wallet-connected', {
          wallet: 'metamask',
        })
        trackWalletConnection()
      } catch (error) {
        window.open('https://metamask.io/', '_blank')

        const errorMessage =
          (typeof error === 'string' && error) || (hasMessage(error) && error.message) || ''
        window.newrelic?.noticeError(errorMessage || title)
        snackbar.addError({ title, text: getHumanError(errorMessage) })
      }
      break
    }
    case WalletOption.KEPLR:
      try {
        await connectKeplr(wallet.chains)
        step.value = { key: 'result', wallet }
        localStorage.setItem('lastWallet', WalletOption.KEPLR)
        localStorage.setItem(
          'connectChains',
          wallet.chains.flatMap(({ status, id }) => (status === 'selected' ? id : [])).join(','),
        )
        analytics.track('wallet-connected', {
          wallet: 'keplr',
        })
        trackWalletConnection()
      } catch (error) {
        window.open('https://www.keplr.app/', '_blank')

        const title = 'Failed to connect to Keplr wallet'
        const errorMessage =
          (typeof error === 'string' && error) || (hasMessage(error) && error.message) || ''
        window.newrelic?.noticeError(errorMessage || title)
        snackbar.addError({ title, text: getHumanError(errorMessage) })
      }
      break
    case 'create-new': {
      const phrase = generatePhrase()
      step.value = { key: 'phrase', phrase, wallet }
      try {
        await connectNewKeystore({ phrase, chains: wallet.chains })
        localStorage.setItem('lastWallet', WalletOption.KEYSTORE)
        localStorage.setItem(
          'connectChains',
          wallet.chains.flatMap(({ status, id }) => (status === 'selected' ? id : [])).join(','),
        )
        analytics.track('wallet-connected', {
          wallet: 'keystore',
          type: 'new',
        })
        trackWalletConnection()
      } catch (error) {
        const title = 'Failed to create new Keystore wallet'
        const errorMessage =
          (typeof error === 'string' && error) || (hasMessage(error) && error.message) || ''
        window.newrelic?.noticeError(errorMessage || title)
        snackbar.addError({ title, text: getHumanError(errorMessage) })
      }
      break
    }
    case 'from-phrase':
      step.value = { key: 'fromPhrase', wallet }
      break
    case WalletOption.KEYSTORE:
      step.value = { key: WalletOption.KEYSTORE, wallet }
      break
  }
}

const onGoToSelectWallet = () => {
  if (isLoadingBalances.value) {
    snackbar.addInfo({
      title: 'Connecting your wallet, please wait',
    })
    return
  }
  step.value = { key: 'selectWallet' }
}
const onGoToNetworkSelect = () => {
  if (isLoadingBalances.value) {
    snackbar.addInfo({
      title: 'Connecting your wallet, please wait',
    })
    return
  }
  if (step.value.key !== WalletOption.KEYSTORE) {
    snackbar.addWarning({
      title: 'Failed to return to network selection',
      text: 'This action is only allowed when restoring keystore wallet from file',
    })
    return
  }
  step.value = {
    key: 'selectNetwork',
    wallet: { ...step.value.wallet, chains: [...step.value.wallet.chains] },
  }
}
const onGoToPhrase = () => {
  if (isLoadingBalances.value) {
    snackbar.addInfo({
      title: 'Connecting your wallet, please wait',
    })
    return
  }
  if (step.value.key !== 'password') {
    snackbar.addWarning({
      title: 'Cannot return to seed phrase',
      text: 'Please create a new keystore wallet first',
    })
    return
  }
  step.value = {
    key: 'phrase',
    phrase: step.value.phrase,
    wallet: step.value.wallet,
  }
}
const onSelectWallet = (wallet: Wallet) => {
  step.value = {
    key: 'selectNetwork',
    wallet,
  }
}
const onToggleChain = (chainId: WalletChain) => {
  if (step.value.key !== 'selectNetwork') {
    snackbar.addWarning({ title: 'Please select a wallet before selecting a network' })
    return
  }
  if (step.value.wallet.id === WalletOption.METAMASK) {
    step.value.wallet.chains.forEach((chain) => {
      if (chain.status === 'disabled') {
        return
      }
      if (chain.id !== chainId) {
        chain.status = 'unselected'
        return
      }
      chain.status = 'selected'
    })
    return
  }
  step.value.wallet.chains.forEach((chain) => {
    if (chain.status === 'disabled') {
      return
    }
    if (chain.id !== chainId) {
      return
    }
    chain.status = chain.status === 'selected' ? 'unselected' : 'selected'
  })
}
const setChainsSelected = (selected: boolean) => {
  if (step.value.key !== 'selectNetwork') {
    snackbar.addWarning({ title: 'Please select a wallet before selecting a network' })
    return
  }
  step.value.wallet.chains.forEach((chain) => {
    if (chain.status === 'disabled') {
      return
    }
    chain.status = selected ? 'selected' : 'unselected'
  })
}
const onSelectAllChains = () => {
  setChainsSelected(true)
}
const onDeselectAllChains = () => {
  setChainsSelected(false)
}
const onClose = () => {
  // * wait for wallet to be connected and the balances to be fetched
  // * then display the networks whose balances have been successfully fetched
  // if (isLoadingBalances.value) {
  //   snackbar.addInfo({ title: 'Connecting your wallet, please wait' })
  //   return
  // }
  step.value = { ...initialStep }
  hideConnectWalletModal()
}

onMounted(async () => {
  if (swapkitWalletStore.isWalletConnected || runtimeConfig.public.NODE_ENV !== 'development') {
    return
  }
  const lastWallet = localStorage.getItem('lastWallet')
  const connectChains = localStorage.getItem('connectChains')?.split(',') ?? []
  switch (lastWallet) {
    case WalletOption.PHANTOM: {
      await connectPhantom()
      break
    }
    case WalletOption.XDEFI: {
      await connectXdefi(
        connectChains.length
          ? xdefiChains.filter((chain) => connectChains.includes(chain.id))
          : xdefiChains,
      )
      break
    }
    case WalletOption.METAMASK: {
      const chain = connectChains.at(0)
      const evmChain = chain && evmChains.find((c) => c.id === chain)
      connectMetamask(evmChain || connectChainsRecord[Chain.Ethereum])
      break
    }
    case WalletOption.KEPLR:
      connectKeplr(
        connectChains.length
          ? keplrChains.filter((chain) => connectChains.includes(chain.id))
          : keplrChains,
      )
      break
    case WalletOption.KEYSTORE:
      if (!runtimeConfig.public.KEYSTORE_PASSPHRASE) {
        return
      }
      if (!validatePhrase(runtimeConfig.public.KEYSTORE_PASSPHRASE)) {
        snackbar.addWarning({ title: 'Please provide a valid keystore passphrase' })
        return
      }
      await connectKeystore({
        phrase: runtimeConfig.public.KEYSTORE_PASSPHRASE,
        chains: chainsToSelectedChains(
          connectChains.length
            ? keystoreChains.filter((chain) => connectChains.includes(chain.id))
            : keystoreChains,
        ),
      })
      break
    default:
      break
  }
})
</script>
