<template>
  <div
    class="rounded-2xl bg-[#191919]"
    :class="[
      !menuCollapsedOnMobile && isWalletConnected ? 'max-md:bg-transparent' : '',
      isWalletConnected ? '' : 'hover:bg-[#172b21]',
    ]"
  >
    <MenuLink
      :component="isWalletConnected ? 'router-link' : 'button'"
      :link-to="isWalletConnected ? '/wallet' : undefined"
      :title="isWalletConnected ? 'Wallet' : 'Connect Wallet'"
      sub-panel-class="!bg-white/0 max-md:pt-[0px] [&>div]:bg-white/0 [&>div]:gap-1 [&>div]:grid"
      :main-btn-class="
        isWalletConnected ? 'hover:!text-[#33FF99]' : 'hover:!text-[#33FF99] hover:bg-transparent'
      "
      :expanded-on-mobile="!menuCollapsedOnMobile"
      is-passive-menu
      hide-status-bar
      @click="!isWalletConnected && showConnectWalletModal()"
      @toggle-collapse="setExpandedOnMobile(false)"
      @toggle-expand="setExpandedOnMobile(true)"
    >
      <template #icon>
        <div
          class="relative my-auto min-w-[1em]"
          :class="isMobile && !isWalletConnected ? 'px-1' : ''"
        >
          <img class="aspect-square w-full" src="/icons/wallet.svg" alt="" />
          <Icon
            v-if="!isWalletConnected"
            class="absolute right-[0.1em] top-[0.1em] text-[0.5em] text-[#33FF99] md:-right-[0.1em] md:-top-[0.1em]"
            icon="mingcute:plus-fill"
          />
        </div>
        <!-- <img
          class="aspect-square min-w-[1em] filter"
          src="/icons/wallet.svg"
          alt=""
        /> -->
      </template>
      <template v-if="isWalletConnected" #default>
        <WalletMenuBody
          class="z-10 overflow-hidden rounded-2xl bg-[#191919] max-md:absolute max-md:right-0 max-md:top-0 max-md:w-[calc(100vw_-_32px)] md:max-w-60"
          @close="setExpandedOnMobile(false)"
        />
      </template>
    </MenuLink>
    <Teleport to="body">
      <!-- <ModalNewSubWalletConnect /> -->
      <ModalSubWalletConnect />
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import { Icon } from '@iconify/vue/dist/iconify.js'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { useDisplay } from 'vuetify'
import MenuLink from '../MenuLink.vue'
import WalletMenuBody from './WalletMenuBody.vue'
import { useConnectWalletModalStore } from '@/store/connectWalletModalStore'
import { useSwapkitWalletStore } from '~/store/swapkitWalletStore'

defineProps<{
  menuCollapsedOnMobile?: boolean
}>()

const emit = defineEmits<{
  toggleMenu: [boolean]
}>()

const display = useDisplay()
const isMobile = computed(() => display.mobile.value)

const swapkitWalletStore = useSwapkitWalletStore()
const { isWalletConnected } = storeToRefs(swapkitWalletStore)

const { showConnectWalletModal } = useConnectWalletModalStore()

const setExpandedOnMobile = (flag: boolean) => {
  emit('toggleMenu', !flag)
}
</script>
