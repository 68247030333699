<template>
  <div
    class="flex h-[460px] w-full flex-col rounded-2xl bg-[#141414] md:min-w-[340px] md:max-w-[340px]"
  >
    <div class="flex h-full w-full flex-col p-5">
      <div class="flex items-start gap-2 text-base">
        <div class="larken [&_*]:larken flex flex-1 flex-col text-base leading-tight">
          <span v-if="!walletConnected" class="text-white"> Downloading & Connecting... </span>
          <span v-else-if="wallet.id === 'create-new'" class="text-white">
            <span class="text-[#33FF99]">Downloaded</span> &
            <span class="text-[#33FF99]">Connected</span>
          </span>
          <span v-else class="text-[#33FF99]"> Connected </span>
        </div>
        <button
          class="flex cursor-pointer items-center justify-center rounded-lg bg-white/10 p-1.5 text-xs opacity-50 hover:opacity-100"
          aria-label="Close dialog"
          @click="onClose"
        >
          <Icon icon="iconamoon:sign-times-duotone" />
        </button>
      </div>
      <div class="flex flex-grow flex-col justify-center overflow-auto">
        <div
          class="mx-auto -mb-20 flex items-center justify-center rounded-full p-8"
          :class="props.wallet.category === 'software' ? 'bg-white' : 'bg-[#ecbb3357]'"
          style="mask-image: linear-gradient(black, transparent 90%)"
        >
          <img
            class="aspect-square w-24 rounded-full object-contain object-center"
            :src="wallet.logo"
            :alt="wallet.name"
          />
        </div>
        <div class="z-0 flex flex-col items-center justify-center gap-2.5">
          <span class="larken mx-auto text-2xl leading-none">{{ props.wallet.name }}</span>
          <template v-if="selectedChains.length > 0">
            <div class="flex justify-center">
              <div
                class="flex"
                :style="{
                  width: `${selectedChains.length * (chainIconSize - chainIconOverlap) + chainIconOverlap}px`,
                }"
              >
                <img
                  v-for="(chain, index) in selectedChains"
                  :key="chain.id"
                  class="relative rounded-full"
                  :style="{
                    width: `${chainIconSize}px`,
                    left: `${index * -chainIconOverlap}px`,
                    zIndex: index,
                  }"
                  :src="chain.src"
                  :alt="chain.title"
                />
              </div>
            </div>
            <div v-if="selectedChains.length > 0" class="mx-auto text-sm text-white/50">
              {{ selectedChains.at(0)?.title }}
              <span
                v-if="selectedChains.length > 2"
                :title="
                  selectedChains
                    .slice(1)
                    .map((chain) => chain.title)
                    .join(', ')
                "
              >
                and {{ selectedChains.length - 1 }} other networks</span
              >
              <span v-else-if="selectedChains.length > 1"
                >and {{ selectedChains.at(1)?.title }}</span
              >
            </div>
          </template>
        </div>
      </div>
    </div>
    <div :ref="onProgressRef" class="h-1.5 w-[0px] bg-[#33FF99]" />
  </div>
</template>

<script setup lang="ts">
import { Icon } from '@iconify/vue/dist/iconify.js'
import { type ComponentPublicInstance, type DeepReadonly, computed } from 'vue'
import { useSwapkitWalletStore } from '~/store/swapkitWalletStore'
import type { Wallet } from '~/utils/walletconnect'

interface IProps {
  wallet: DeepReadonly<Wallet>
}
const props = defineProps<IProps>()
const emits = defineEmits<{
  close: []
}>()

const swapkitWalletStore = useSwapkitWalletStore()
const { isWalletTypeConnected } = swapkitWalletStore
const walletConnected = computed(() => {
  return isWalletTypeConnected(props.wallet.type)
})

const chainIconSize = 20
const chainIconOverlap = chainIconSize * 0.25

const onClose = () => {
  emits('close')
}

const selectedChains = computed(() => {
  return props.wallet.chains.filter((chain) => chain.status === 'selected')
})

const onProgressRef = (ref: Element | ComponentPublicInstance | null) => {
  if (!(ref instanceof HTMLDivElement)) {
    return
  }
  const duration = 4000
  const reducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches
  if (reducedMotion) {
    setTimeout(onClose, duration)
    return
  }
  const animation = ref.animate([{ width: '0%' }, { width: '100%' }], {
    duration,
    fill: 'forwards',
    easing: 'linear',
  })
  animation.onfinish = onClose
}
</script>
