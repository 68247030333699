<template>
  <div
    class="flex h-[460px] w-full flex-col rounded-2xl bg-[#141414] p-5 md:min-w-[340px] md:max-w-[340px]"
  >
    <div class="flex items-start gap-2 text-base">
      <div class="larken [&_*]:larken flex flex-1 flex-col text-base leading-tight text-white">
        Recover Keystore Wallet from Phrase
      </div>
      <button
        class="flex cursor-pointer items-center justify-center rounded-lg bg-white/10 p-1.5 text-xs opacity-50 hover:opacity-100"
        @click="onClose"
      >
        <Icon icon="iconamoon:sign-times-duotone" />
      </button>
    </div>
    <div class="flex flex-grow flex-col overflow-auto">
      <div class="mt-5 text-xs text-white/50">
        Enter your seed phrase to recover your keystore. This will create a new keystore file and
        download it.
      </div>
      <div class="mt-5 flex flex-1 flex-col gap-2.5">
        <label for="from-phrase-seed-phrase" class="text-[10px] leading-none text-white/50"
          >Seed Phrase</label
        >
        <textarea
          id="from-phrase-seed-phrase"
          class="flex flex-1 resize-none flex-col items-center justify-center rounded-2xl bg-white/5 p-5 outline-none"
          :value="phrase"
          @input="onPhraseChange"
        />
      </div>
      <div class="mt-2.5 flex flex-col gap-2.5">
        <label for="from-phrase-password" class="text-[10px] leading-none text-white/50"
          >Password</label
        >
        <div class="flex items-center rounded-2xl border border-solid border-white/10 p-5">
          <input
            id="from-phrase-password"
            :type="pwdVisible ? 'text' : 'password'"
            :value="password"
            class="w-full text-xs leading-none outline-none"
            placeholder="Password..."
            @input="onPwdChange"
          />
          <button class="flex cursor-pointer" @click="onTogglePwdVisible">
            <Icon :icon="pwdVisible ? 'mage:eye-off' : 'ph:eye'" />
          </button>
        </div>
      </div>
      <button
        :class="[
          '[&_*]:larken larken col-span-2 mt-5 flex cursor-pointer items-center justify-center rounded-full bg-[#ECBA33] px-5 py-4 text-center text-base leading-none text-[#1D2021]',
          { 'opacity-60': !phrase || isLoadingBalances },
        ]"
        :aria-disabled="!phrase"
        @click="onConnect"
      >
        <span v-if="isLoadingBalances" class="flex items-center gap-1">
          Connecting <Icon icon="line-md:loading-loop" />
        </span>
        <span v-else data-testid="connect-keystore-wallet" class="flex items-center gap-2.5">
          Connect
        </span>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Icon } from '@iconify/vue/dist/iconify.js'
import { validatePhrase } from '@xchainjs/xchain-crypto'
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useSnackbarMessage } from '~/store/snackbar'
import { useSwapkitWalletStore } from '~/store/swapkitWalletStore'

const props = defineProps<{
  onConnect: (params: { phrase: string; password: string }) => Promise<unknown>
}>()

const emits = defineEmits<{
  close: []
}>()

const snackbar = useSnackbarMessage()
const swapkitWalletStore = useSwapkitWalletStore()
const { isLoadingBalances } = storeToRefs(swapkitWalletStore)

const phrase = ref<string>('')
const password = ref<string>('')
const pwdVisible = ref(false)
const onPwdChange = (e: Event) => {
  if (!(e.target instanceof HTMLInputElement)) {
    return
  }
  password.value = e.target.value
}
const onTogglePwdVisible = () => {
  pwdVisible.value = !pwdVisible.value
}
const onPhraseChange = (e: Event) => {
  if (!(e.target instanceof HTMLTextAreaElement)) {
    return
  }
  phrase.value = e.target.value
}
const onConnect = async () => {
  const phraseValue = phrase.value.replace(/\s+/g, ' ')
  if (!validatePhrase(phraseValue)) {
    snackbar.addError({ title: 'Please enter a valid seed phrase' })
    return
  }
  await props.onConnect({ phrase: phraseValue, password: password.value })
}
const onClose = () => {
  emits('close')
}
</script>
